import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { reactI18nextModule } from 'react-i18next';

i18n
  .use(detector)
  .use(reactI18nextModule)
  .use(backend)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupCookie: 'locale',
      caches: ['cookie'],
      cookieDomain: 'insight-rx.com',
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    fallbackLng: 'en',
    // parseMissingKeyHandler: () => 'Something went wrong',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;

export const t = i18n.t;

