import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

function SubmitButton(props) {
  const { isSubmitting, children } = props;
  const buttonSx = { mt: 2, minWidth: '30ch' };

  return isSubmitting ? (
    <LoadingButton
      data-testid="loading-button"
      loading
      variant="contained"
      sx={ buttonSx }
    >
      {children}
    </LoadingButton>
  ) : (
    <Button
      type="submit"
      variant="contained"
      sx={ buttonSx }
    >
      {children}
    </Button>
  );
}

export default SubmitButton;
