import { useState } from 'react';
import { SendMFACodeForm } from './SendMFACodeForm';

import {useCodeMFA, useResendCodeMFA} from '../../services/codeMFA';

function SendMFACode({ sendTo = 'smsMFA'}) {
  const [MFACode, setMFACode] = useState('');
  const [{ error, isLoading }, sendMFACode, ] = useCodeMFA();
  const [ , resendCodeMFA, ] = useResendCodeMFA();

  return (
    <SendMFACodeForm
      sendTo={sendTo}
      MFACode={MFACode}
      setMFACode={setMFACode}
      onFormSubmit={() => sendMFACode({ code: MFACode })}
      isSubmitting={isLoading}
      formError={error}
      onResend={() => resendCodeMFA()}
    />
  );
}

export default SendMFACode;
