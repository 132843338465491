import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import { useLogin } from '../../services/authentication';
import {t} from '../../lib/i18n';

function LoginFormContainer() {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [{ isLoading, error }, signIn] = useLogin();

  const errKey = searchParams.get('errKey');
  const appName = searchParams.get('appName');

  const initialError = errKey && t(`auth.errors.${errKey}`, {appName: appName || 'the url'});

  return (
    <LoginForm
      username={username}
      setUsername={setUsername}
      password={password}
      setPassword={setPassword}
      onFormSubmit={() => signIn({ login: username, password })}
      isSubmitting={isLoading}
      formError={initialError || error}
    />
  );
}

export default LoginFormContainer;
