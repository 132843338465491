import { codeMFAUrl, resendMFACodeUrl } from '../lib/paths';
import { usePostApi } from '../lib/hooks/requests';

export const useCodeMFA = () => {
  return usePostApi(codeMFAUrl);
};

export const useResendCodeMFA = () => {
  return usePostApi(resendMFACodeUrl);
};

