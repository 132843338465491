import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormContainer from '../common/FormContainer';
import { t } from '../../lib/i18n';

function ForgotPasswordSuccess() {
  return (
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <Typography variant="h5" gutterBottom>
          {t('auth.forgotPassword.success.checkEmail')}
        </Typography>
        <Typography variant="body2" gutterBottom component="div" data-testid="forgot-password">
          {t('auth.forgotPassword.success.emailSent')}
        </Typography>
      </Paper>
    </FormContainer>
  );
}

export default ForgotPasswordSuccess;
