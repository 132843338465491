import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormContainer from '../common/FormContainer';
import { useRedirectUrl } from '../../lib/hooks/useRedirect';
import { useEffect } from 'react';
import { t } from '../../lib/i18n';

export const ChangePasswordSuccess = () => {
  const redirectUrl = useRedirectUrl();

  useEffect(() => {
    setTimeout(() => window.location.replace(redirectUrl), 1000);
  }, []);

  return (
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <Typography variant="h5" gutterBottom>
          {t('auth.changePassword.message')}
        </Typography>
      </Paper>
    </FormContainer>
  );
};
