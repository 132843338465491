import { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useForgotPassword } from '../../services/forgotPassword';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [{ error, isLoading, isSuccess }, forgotPassword] = useForgotPassword();

  return (
    <ForgotPasswordForm
      email={email}
      setEmail={setEmail}
      onFormSubmit={() => forgotPassword({ login: email })}
      isSubmitting={isLoading}
      formError={error}
      success={isSuccess}
    />
  );
}

export default ForgotPassword;
