function ErrorPage(props) {
  const { code, title, message } = props;
  const heading = code ? `${code} - ${title}` : title;
  return (
    <>
      <h1>{heading}</h1>
      <p>{message}</p>
    </>
  );
}

export default ErrorPage;
