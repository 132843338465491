import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './lib/theme';
import Login from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordConfirm from './components/ForgotPasswordConfirm';
import SendMFACode from './components/SendMFACode';
import SsoLoading from './components/SsoLoading';
import ErrorPage from './components/common/ErrorPage';
import { withNamespaces } from 'react-i18next';
import { t } from './lib/i18n';

import ChangePassword from './components/ChangePassword';
import { useState } from 'react';
import AuthGuard from './components/AuthGuard';
import AuthContext from './components/AuthGuard/AuthContexts';

function App() {
  const [user, setUser] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <AuthContext.Provider value={{ user, setUser }}>
          <AuthGuard>
            <Routes>
              <Route path="*" element={ <ErrorPage code="404" title={t('auth.errors.notFoundTitle')} message={t('auth.errors.notFoundMessage')}/> } />
              <Route path="/" element={ <Login /> } />
              <Route path="forgot-password" element={ <ForgotPassword /> } />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="forgot-password/confirm" element={ <ForgotPasswordConfirm />} />
              <Route path="send-mfa-code" element={ <SendMFACode /> } />
              <Route path="sso/saml" element={ <SsoLoading />} />
              <Route path="epic/oauth" element={ <SsoLoading />} />
              <Route path="cerner/oauth" element={ <SsoLoading />} />
            </Routes>
          </AuthGuard>
        </AuthContext.Provider>
      </Container>
    </ThemeProvider>
  );
}

export default withNamespaces()(App);
