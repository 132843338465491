import { useEffect, useState } from 'react';
import useQuery from '../../lib/hooks/useQuery';
import { t } from '../../lib/i18n';
import LoadingPage from '../common/LoadingPage';
import ErrorPage from '../common/ErrorPage';
import { ssoCallback } from '../../services/sso';

function SsoLoading() {
  const [ error, setError ] = useState(null);
  const [ success, setSuccess ] = useState(false);
  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');
  const cernerErrorUri = query.get('error_uri');

  useEffect(() => {
    ssoCallback({ code, state, cernerErrorUri }, setError, setSuccess);
  }, [ code, state ]);

  if (error !== null) {
    return (
      <ErrorPage title={t('auth.sso.ssoLoginError')} message={error}/>
    );
  }

  return (
    <LoadingPage />
  );
}

export default SsoLoading;
