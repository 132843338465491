import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


function LoadingPage() {
  return (
    <Paper
      elevation={0}
      square={true}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        width: 150,
        m: 'auto',
        p: 1
      }}>
      <CircularProgress />
      <Typography>
        Loading…
      </Typography>
    </Paper>
  );
}

export default LoadingPage;
