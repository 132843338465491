import jwt_decode from 'jwt-decode';

export const createHandler = (handleEvent) => {
  return (event) => {
    const newValue = event.target.value || '';
    handleEvent(newValue);
  };
};

export const extractUserData = (res) => {
  const { email, username, allowedSubdomains } = jwt_decode(res.data.tokens.accessToken);
  const { challengeStep } = res.data;

  //check if password has not expired
  const challengeMessage = getPasswordExpirationMessage(res.data.daysToExpire);

  return {email, username, allowedSubdomains, challengeStep, challengeMessage};
};

export const getPasswordExpirationMessage = (daysLeft) => {
  if (daysLeft <= 0) return 'Your password is expired. Please change your password.';
  return `Your password will expire in ${daysLeft} days. Please change your password.`;
};

export const hasAccessToSubdomain = (allowedSubdomains, redirectUrl) => {
  return allowedSubdomains?.some((subdomain) => redirectUrl.includes(`https://${subdomain}.insight-rx.com`));
};
