import { useContext } from 'react';
import AuthContext from './AuthContexts';
import SignInSuccess from '../SignInSuccess';
import ErrorPage from '../common/ErrorPage';
import SendMFACode from '../SendMFACode';
import ChangePassword from '../ChangePassword';
import { useRedirectUrl } from '../../lib/hooks/useRedirect';
import { hasAccessToSubdomain } from '../../lib/utils';
import {t} from '../../lib/i18n';

const AuthGuard = ({ children }) => {
  const redirectUrl = useRedirectUrl();
  const { user } = useContext(AuthContext);

  if (!user || !redirectUrl) return  <>{children}</>;

  if (!hasAccessToSubdomain(user.allowedSubdomains, redirectUrl)) return <ErrorPage code="401" title={t('auth.errors.unauthorized')}/>;

  if (user?.challengeStep === 'AUTH') return <SignInSuccess />;
  if (user?.challengeStep === 'SMS_MFA_REQUIRED') return <SendMFACode sendTo='smsMFA'/>;
  if (user?.challengeStep === 'EMAIL_MFA_REQUIRED') return <SendMFACode sendTo='emailMFA'/>;
  if (user?.challengeStep === 'PASSWORD_EXPIRED') return <ChangePassword expirationMsg={user?.challengeMessage} />;
  if (user?.challengeStep === 'CONSIDER_PASSWORD_CHANGE') return <ChangePassword expirationMsg={user?.challengeMessage} />;
  return <>{children}</>;
};

export default AuthGuard;
