import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormContainer from '../common/FormContainer';
import LoginLink from '../common/LoginLink';
import { t } from '../../lib/i18n';

function SuccessMessage() {
  return (
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <Typography variant="h5" gutterBottom>
          {t('auth.resetPassword.success.title')}
        </Typography>
        <Typography variant="body2" gutterBottom component="div">
          {t('auth.resetPassword.success.message')}
        </Typography>
      </Paper>
      <LoginLink/>
    </FormContainer>
  );
}

export default SuccessMessage;
