import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Link from '@mui/material/Link';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import FormContainer from '../common/FormContainer';
import FormError from '../common/FormError';
import SubmitButton from '../common/SubmitButton';
import { t } from '../../lib/i18n';
import {textFieldSx} from '../../lib/constants';
import {createHandler} from '../../lib/utils';


export const SendMFACodeForm = (props) => {
  const {
    sendTo,
    MFACode,
    setMFACode,
    isSubmitting,
    onFormSubmit,
    formError,
    onResend,
  } = props;

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  return(
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <Typography variant="h5" gutterBottom>
          {t('auth.mfa.title')}
        </Typography>
        <FormError formError={formError}/>
        <ValidatorForm
          data-testid="login-form"
          onSubmit={() => onFormSubmit()}
          instantValidate={false}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>

            <Typography variant="body1" gutterBottom> {t(`auth.mfa.sendTo.${sendTo}`)} </Typography>

            <TextValidator
              sx={textFieldSx}
              id="SendMFACode_code"
              name="code"
              type="code"
              label={t('auth.mfa.input')}
              variant="filled"
              value={MFACode}
              onChange={createHandler(setMFACode)}
              validators={['required']}
              errorMessages={[t('auth.mfa.errors.codeRequired')]}
            />
            <SubmitButton isSubmitting={isSubmitting}>
              {t('auth.mfa.submitButton')}
            </SubmitButton>
          </Box>
        </ValidatorForm>
      </Paper>
      <Link
        component='button'
        underline='hover'
        sx={{
          mt: 1,
          fontSize: 'medium'
        }}
        onClick={() => {
          onResend();
          setSnackBarOpen(true);
        }}
      >
        {t('auth.mfa.resendCode')}
      </Link>
      <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={() => setSnackBarOpen(false)}>
        <Alert variant="filled" severity="info" >
          {t('auth.mfa.resendCodeToast')}
        </Alert>
      </Snackbar>

    </FormContainer>
  );
};
