import Alert from '@mui/material/Alert';

function FormError(props) {
  const { formError } = props;

  return formError ? (
    <Alert
      severity="error"
      variant="outlined"
    >
      { formError }
    </Alert>
  ) : (
    null
  );
}

export const FormWarning = ({ formWarning }) => {
  if (formWarning) {
    return (
      <Alert severity="warning" variant="outlined">
        {formWarning}
      </Alert>
    );
  }
};

export default FormError;
