import { useState } from 'react';
import ForgotPasswordConfirmForm from './ForgotPasswordConfirmForm';
import { useSearchParams } from 'react-router-dom';
import { useConfirmForgotPassword } from '../../services/forgotPasswordConfirm';

function ForgotPasswordConfirm() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [{ error, isLoading, isSuccess }, confirmForgotPassword] = useConfirmForgotPassword();
  const [params] = useSearchParams();

  const code = params.get('code');
  const login = params.get('user');


  return (
    <ForgotPasswordConfirmForm
      password={newPassword}
      setPassword={setNewPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      onFormSubmit={() => confirmForgotPassword({ newPassword, confirmPassword, code, login })}
      isSubmitting={isLoading}
      formError={error}
      success={isSuccess}
    />
  );
}

export default ForgotPasswordConfirm;
