import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import SubmitButton from '../common/SubmitButton';
import FormContainer from '../common/FormContainer';
import SuccessMessage from './SuccessMessage';
import FormError from '../common/FormError';
import { t } from '../../lib/i18n';
import {textFieldSx} from '../../lib/constants';
import {createHandler} from '../../lib/utils';


function ForgotPasswordConfirmForm(props) {
  const {
    password,
    confirmPassword,
    isSubmitting,
    setPassword,
    setConfirmPassword,
    onFormSubmit,
    success,
    formError
  } = props;

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value === password) {
        return true;
      }
      return false;
    });
    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [ password ]);

  function handleFormSubmit() {
    onFormSubmit();
  }

  if (success === true) {
    return <SuccessMessage />;
  }

  return (
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <Typography variant="h5" gutterBottom>
          {t('auth.resetPassword.title')}
        </Typography>
        <FormError formError={formError}/>
        <ValidatorForm
          data-testid="login-form"
          onSubmit={handleFormSubmit}
          instantValidate={false}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <TextValidator
              sx={textFieldSx}
              id="ForgotPasswordConfirm-password"
              name="password"
              type="password"
              label={t('auth.common.fields.password')}
              variant="filled"
              value={password}
              onChange={createHandler(setPassword)}
              validators={['required']}
              errorMessages={[t('auth.common.required.password')]}
            />
            <TextValidator
              sx={textFieldSx}
              id="ForgotPasswordConfirm-password-confirm"
              name="password-confirm"
              type="password"
              label={t('auth.common.fields.passwordConfirmation')}
              variant="filled"
              value={confirmPassword}
              onChange={createHandler(setConfirmPassword)}
              validators={['required', 'isPasswordMatch']}
              errorMessages={[t('auth.common.required.passwordConfirmation'), t('auth.resetPassword.errors.isPasswordMatch')]}
            />
            <SubmitButton isSubmitting={isSubmitting}>
              {t('auth.resetPassword.submitButton')}
            </SubmitButton>
          </Box>
        </ValidatorForm>
      </Paper>
    </FormContainer>
  );
}

export default ForgotPasswordConfirmForm;
