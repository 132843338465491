import { useEffect } from 'react';
import FormContainer from '../common/FormContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { t } from '../../lib/i18n';
import { useRedirectUrl } from '../../lib/hooks/useRedirect';

const SignInSuccess = () => {
  const redirectUrl = useRedirectUrl();

  useEffect(() => {
    setTimeout(() => window.location.replace(redirectUrl));
  }, []);

  return (
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <Typography variant="h5" gutterBottom>
          {t('auth.redirecting')}
        </Typography>
      </Paper>
    </FormContainer>
  );
};


export default SignInSuccess;
