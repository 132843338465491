import Box from '@mui/material/Box';
import logo from './logo.png';

function FormContainer({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
      alignItems="center"
    >
      <Box
        component="img" 
        sx={{
          height: 45,
          width: 200,
          mt: 4,
          mb: 3
        }}
        src={logo}
        alt="InsightRX"
      />
      {children}
    </Box>
  );
}

export default FormContainer;
