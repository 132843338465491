import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import FormError from '../common/FormError';
import FormContainer from '../common/FormContainer';
import LoginFormForm from './LoginFormForm';
import { t } from '../../lib/i18n';

function LoginForm(props) {
  const { username, setUsername, password, setPassword, onFormSubmit, isSubmitting, formError } = props;

  return (
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <FormError formError={ formError }/>
        <LoginFormForm
          username={ username }
          setUsername={ setUsername }
          password={ password }
          setPassword={ setPassword }
          onFormSubmit={ onFormSubmit }
          isSubmitting={ isSubmitting }
        />
      </Paper>
      <Link
        to="/forgot-password"
        component={ RouterLink }
        underline="hover"
        sx={{
          mt: 1
        }}
      >
        {t('auth.login.forgotPassword')}
      </Link>
    </FormContainer>
  );
}

export default LoginForm;
