import Link from '@mui/material/Link';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Link as RouterLink } from 'react-router-dom';
import { t } from '../../lib/i18n';

function LoginLink(props) {
  const { children } = props;

  return (
    <Link
      to="/"
      component={ RouterLink }
      underline="hover"
      sx={{
        mt: 1,
        display: 'flex'
      }}
    >
      <ArrowLeftIcon sx={{ marginLeft: '-24px'}}/>
      {t('auth.common.button.backToLogin')}
    </Link>
  );
}

export default LoginLink;
