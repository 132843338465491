import Box from '@mui/material/Box';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { t } from '../../lib/i18n';
import {textFieldSx} from '../../lib/constants';
import SubmitButton from '../common/SubmitButton';


function LoginFormForm(props) {
  const { username, password, isSubmitting, setUsername, setPassword, onFormSubmit } = props;

  function handleUsernameChange(event) {
    const newValue = event.target.value || '';
    setUsername(newValue);
  }

  function handlePasswordChange(event) {
    const newValue = event.target.value || '';
    setPassword(newValue);
  }

  function handleFormSubmit() {
    onFormSubmit();
  }

  return (
    <ValidatorForm
      data-testid="login-form"
      onSubmit={ handleFormSubmit }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <TextValidator
          sx={ textFieldSx }
          id="LoginForm-username"
          label={t('auth.common.fields.username')}
          variant="filled"
          value={ username }
          onChange={ handleUsernameChange }
          validators={['required']}
          errorMessages={[t('auth.common.required.username')]}
        />
        <TextValidator
          sx={ textFieldSx }
          id="LoginForm-password"
          type="password"
          label={t('auth.common.fields.password')}
          variant="filled"
          value={ password }
          onChange={ handlePasswordChange }
          validators={['required']}
          errorMessages={[t('auth.common.required.password')]}
        />
        <SubmitButton isSubmitting={ isSubmitting }>
          {t('auth.login.logIn')}
        </SubmitButton>
      </Box>
    </ValidatorForm>
  );
}

export default LoginFormForm;
