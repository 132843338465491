import { t } from '../lib/i18n';
import { baseUrl } from '../lib/url';

async function makeCallbackRequest(queryParams, setError, setSuccess) {
  const url = `${baseUrl}/api/v1/sso/callback?${queryParams}`;
  const response = await fetch(url, { credentials: 'same-origin' });

  if (response.ok) {
    const { redirectUrl } = await response.json();
    if (redirectUrl) {
      window.location.replace(redirectUrl);
      setSuccess(true);
    }
  } else if (response.status >= 400 && response.status <= 499) {
    setError(t('auth.errors.ssoLoginFailure', { httpCode: response.status}));
  } else {
    setError(t('auth.errors.unexpectedError'));

  }
}

export async function ssoCallback(params, setError, setSuccess) {
  try {
    const { code, state, cernerErrorUri } = params;
    const { identityProviderName } = JSON.parse(state);
    if(cernerErrorUri) {
      window.location.replace(cernerErrorUri);
    }
    if (!code || !state || !identityProviderName) throw new Error();

    const queryParams = new URLSearchParams({
      code,
      identityProviderName,
      state,
    });

    makeCallbackRequest(queryParams, setError, setSuccess);
  } catch (err) {
    setError(t('auth.errors.unexpectedError'));
  }
}
