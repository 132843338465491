import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import SubmitButton from '../common/SubmitButton';
import FormContainer from '../common/FormContainer';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import FormError from '../common/FormError';
import LoginLink from '../common/LoginLink';
import { t } from '../../lib/i18n';
import {textFieldSx} from '../../lib/constants';


function ForgotPasswordForm(props) {
  const { email, isSubmitting, setEmail, onFormSubmit, success, formError } = props;

  function handleEmailChange(event) {
    const newValue = event.target.value || '';
    setEmail(newValue);
  }

  function handleFormSubmit() {
    onFormSubmit();
  }

  if (success === true) {
    return <ForgotPasswordSuccess />;
  }

  return (
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <Typography variant="h5" gutterBottom>
          {t('auth.forgotPassword.title')}
        </Typography>
        <Typography variant="body2" gutterBottom component="div" data-testid="forgot-password">
          {t('auth.forgotPassword.description')}
        </Typography>
        <FormError formError={ formError }/>
        <ValidatorForm
          data-testid="login-form"
          onSubmit={ handleFormSubmit }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <TextValidator
              sx={ textFieldSx }
              id="ForgotPasswordForm-username"
              label={ t('auth.common.fields.email') }
              variant="filled"
              value={ email }
              onChange={ handleEmailChange }
              validators={['required']}
              errorMessages={[t('auth.common.required.email')]}
            />
            <SubmitButton isSubmitting={ isSubmitting }>
              {t('auth.forgotPassword.submitButton')}
            </SubmitButton>
          </Box>
        </ValidatorForm>
      </Paper>
      <LoginLink/>
    </FormContainer>
  );
}

export default ForgotPasswordForm;
