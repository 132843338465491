import { baseUrl } from '../constants';
import {useCallback, useContext, useEffect, useState} from 'react';
import {t} from '../i18n';

import AuthContext from '../../components/AuthGuard/AuthContexts';
import { extractUserData } from '../utils';

export const usePostData = (url) => {
  const [res, setRes] = useState({ data: null, isLoading: false, isSuccess: false, error: '' });
  const finalUrl = baseUrl + url;

  const callAPI = useCallback(
    async (payload) => {
      setRes((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const response = await fetch(finalUrl, {
          credentials: 'include',
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        const errResponse = data.errData ? Object.values(data.errData) : data.message;
        if (response.ok) {
          setRes({ data, isLoading: false, isSuccess: true, error: '' });
        } else if (response.status >= 500) {
          setRes({ data, isLoading: false, isSuccess: false, error: t('auth.errors.unexpectedError') });
        } else {
          setRes({ data, isLoading: false, isSuccess: false, error: errResponse || t('auth.errors.unexpectedError') });
        }
        return data;
      } catch (e) {
        setRes({ isLoading: false, isSuccess: false, error: t('auth.errors.unexpectedError') });
      }
    },
    [finalUrl]
  );
  return [res, callAPI];
};

export const usePostApi = (url) => {
  const { setUser } = useContext(AuthContext);
  const [res, callAPI] = usePostData(url);

  useEffect(() => {
    if (res.isSuccess) {
      //set user
      setUser({ ...extractUserData(res) });
    }
  }, [res, setUser]);

  return [res, callAPI];
};
