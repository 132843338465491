import { Box, Paper, Typography, Button } from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import SubmitButton from '../common/SubmitButton';
import FormContainer from '../common/FormContainer';
import FormError, { FormWarning } from '../common/FormError';
import { getPasswordChangeInputs } from './formData';
import { ChangePasswordSuccess } from './ChangePasswordSuccess';
import { useRedirectUrl } from '../../lib/hooks/useRedirect';
import { t } from '../../lib/i18n';
import {textFieldSx} from '../../lib/constants';

const boxStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' };

export const ChangePasswordForm = (props) => {
  const { isSubmitting, onFormSubmit, formError, formWarning, isSuccess } = props;
  const redirectUrl = useRedirectUrl();
  const inputs = getPasswordChangeInputs({ ...props });

  if (isSuccess === true) {
    return <ChangePasswordSuccess />;
  }

  return (
    <FormContainer>
      <Paper square sx={{ p: 3, width: 425 }}>
        <Typography variant="h5" gutterBottom>
          {t('auth.changePassword.title')}
        </Typography>
        <FormError formError={formError} />
        <FormWarning formWarning={formWarning} />
        <ValidatorForm data-testid="login-form" onSubmit={onFormSubmit}>
          <Box sx={boxStyle}>
            {inputs.map(({ setter, ...props }, index) => (
              <TextValidator
                {...props}
                key={index}
                type="password"
                variant="filled"
                validators={['required']}
                onChange={(e) => setter(e.target.value)}
                sx={textFieldSx}
              />
            ))}
            <SubmitButton href={redirectUrl} isSubmitting={isSubmitting}>
              {t('auth.changePassword.submitButton')}
            </SubmitButton>
            <br />
            {formWarning && (
              <Button type="button" href={redirectUrl}>
                {t('auth.common.button.skip')}
              </Button>
            )}
          </Box>
        </ValidatorForm>
      </Paper>
    </FormContainer>
  );
};
