import { t } from '../../lib/i18n';

export const getPasswordChangeInputs = ({
  password,
  newPassword,
  confirmPassword,
  setPassword,
  setNewPassword,
  setConfirmPassword,
}) => [
  {
    id: 'ChangePasswordForm-password',
    label: t('auth.changePassword.fields.currentPassword'),
    value: password,
    setter: setPassword,
    errorMessages: ['auth.changePassword.required.currentPassword'],
  },
  {
    id: 'ChangePasswordForm-newPassword',
    label: t('auth.changePassword.fields.newPassword'),
    value: newPassword,
    setter: setNewPassword,
    errorMessages: ['auth.changePassword.required.newPassword'],
  },
  {
    id: 'ChangePasswordForm-confirmNewPassword',
    label: t('auth.changePassword.fields.confirmPassword'),
    value: confirmPassword,
    setter: setConfirmPassword,
    errorMessages: ['auth.changePassword.required.confirmPassword'],
  },
];
