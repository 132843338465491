import { useState } from 'react';
import { ChangePasswordForm } from './ChangePasswordForm';
import { useChangePassword } from '../../services/changePassword';

const ChangePassword = ({ expirationMsg = {} }) => {
  const { expirationError, expirationWarning } = expirationMsg;
  const [previousPassword, setPreviousPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [{ error, isLoading, isSuccess }, changePassword] = useChangePassword();

  return (
    <ChangePasswordForm
      password={previousPassword}
      setPassword={setPreviousPassword}
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      onFormSubmit={() => changePassword({ previousPassword, newPassword, confirmPassword })}
      isSubmitting={isLoading}
      formError={error || expirationError}
      formWarning={expirationWarning}
      isSuccess={isSuccess}
    />
  );
};

export default ChangePassword;
