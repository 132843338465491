import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#35BCB1',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#193046',
      paper: 'rgba(82,104,119,0.27)',
    },
  },
});

export default theme;
